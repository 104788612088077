import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import './App.css';
import MainPage from './../../hoc/MainPage/MainPage';

import AdminPage from '../../hoc/AdminPage/AdminPage';
import ProductsPage from '../../hoc/ProductsPage/ProductsPage';
import AdminSlider from './../../hoc/AdminPage/Slider/Slider';
import AdminSliderEnglish from './../../hoc/AdminPage/Slider/SliderEnglish';

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={MainPage} />
          <Route path="/admin" component={AdminPage} />
          <Route path="/products" component={ProductsPage} exact />
          <Route path="/slider" component={AdminSlider} exact />
          <Route path="/slider/english" component={AdminSliderEnglish} exact />
        </Switch>
      </Router>
    );
  }
}

export default App;
