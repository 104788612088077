import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
// import img1 from './../../assets/carousel/kps-1.jpg';
// import img2 from './../../assets/carousel/kps-2.jpg';
// import img3 from './../../assets/carousel/kps-3.jpg';

// import img6 from './../../assets/carousel/kps-6.jpg';

// import img8 from './../../assets/carousel/kps-8.jpg';

// import img11 from './../../assets/carousel/kps-11.jpg';
// import img12 from './../../assets/carousel/kps-12.jpg';
import axios from 'axios';

import './Carousel.css';

class ImageCarousel extends Component {
  state = {
    pictures: undefined,
    width: 0
  };

  constructor(props) {
    super(props);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (localStorage.getItem('lang') !== 'eng') {
      axios
        .get('https://keypro-back.herokuapp.com/api/v1/admin/slider', {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.setState({ pictures: response.data.data });
        })
        .catch(e => {
          console.log(e);
        });
    } else if (localStorage.getItem('lang') === 'eng') {
      axios
        .get('https://keypro-back.herokuapp.com/api/v1/admin/slider/english', {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(response => {
          this.setState({ pictures: response.data.data });
        })
        .catch(e => {
          console.log(e);
        });
    }
  };

  render() {
    // console.log(this.state.width);
    const carouselWidth = this.state.width > 768 ? this.state.width / 1.35 : this.state.width / 1.175;
    return (
      <div className="carousel-container">
        <Carousel
          className="slider"
          emulateTouch
          showStatus={false}
          showThumbs={false}
          autoPlay
          interval={5000}
          infiniteLoop
          useKeyboardArrows
          // width="1300px"
          width={carouselWidth}
        >
          {this.state.pictures
            ? this.state.pictures.map((picture, index) => {
                return (
                  <div key={index}>
                    <img src={picture.link} alt="key pro stand 360" />
                  </div>
                );
              })
            : ''}
        </Carousel>
      </div>
    );
  }
}

export default ImageCarousel;
