import React, { Component } from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import americanexpress from './../../assets/footer/americanexpress.png';
import elo from './../../assets/footer/elo.png';
import mastercard from './../../assets/footer/mastercard.png';
import paypal from './../../assets/footer/paypal.png';
import visa from './../../assets/footer/visa.png';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  render() {
    return (
      <footer>
        <svg
          style={{ display: 'block', width: '100%' }}
          viewBox="0 0 1440 180"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 60.2L34.3 65.1853C68.6 70.5469 137 79.9531 206 90.3C274.3 100.647 343 110.053 411 100.365C480 90.3 549 60.2 617 70.2647C685.7 79.9531 754 130.747 823 145.515C891.4 160.847 960 140.153 1029 115.415C1097.1 90.3 1166 60.2 1234 40.1647C1302.9 19.7531 1371 10.3469 1406 4.98531L1440 0V301H1405.7C1371.4 301 1303 301 1234 301C1165.7 301 1097 301 1029 301C960 301 891 301 823 301C754.3 301 686 301 617 301C548.6 301 480 301 411 301C342.9 301 274 301 206 301C137.1 301 69 301 34 301H0V60.2Z"
            fill="#EFEFEF"
          />
        </svg>
        <div style={{ width: '100%', background: 'rgb(239, 239, 239)' }}>
          <div className="footer-wrapper">
            <div className="container">
              <div className="left section">
                <div className="footer-title">
                  <h3>{this.state.lang.mainPage.footer.columns[0].title}</h3>
                </div>

                <div className="footer-links footer-links-left">
                  <a href={`http://${window.location.host}#News`}>
                    {this.state.lang.mainPage.footer.columns[0].links[0]}
                  </a>
                  <a href={`http://${window.location.host}#About`}>
                    {this.state.lang.mainPage.footer.columns[0].links[1]}
                  </a>
                  {/* <a href="/#Artists">Artists</a> */}
                  <a href={`http://${window.location.host}/products`}>
                    {this.state.lang.mainPage.footer.columns[0].links[2]}
                  </a>
                  <a href={`http://${window.location.host}#Contact`}>
                    {this.state.lang.mainPage.footer.columns[0].links[3]}
                  </a>
                </div>
              </div>
              <div className="middle section">
                <div className="footer-title">
                  <h3>{this.state.lang.mainPage.footer.columns[1].title}</h3>
                </div>

                <div className="footer-links footer-links-middle">
                  <a
                    href="https://www.facebook.com/keyprostand360"
                    className="facebook-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faFacebookF}
                      size="2x"
                      style={{ color: '#3b5998' }}
                      className="icon"
                    />
                  </a>

                  <a
                    href="https://www.instagram.com/keyprostand360/"
                    className="facebook-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faInstagram}
                      size="2x"
                      style={{ color: '#C13584' }}
                      className="icon"
                    />
                  </a>

                  <a
                    href="https://www.youtube.com/channel/UCM940IX_8eE48GQ2F926WtA"
                    className="facebook-icon"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size="2x"
                      style={{ color: '#FF0000' }}
                      className="icon"
                    />
                  </a>
                </div>
              </div>
              <div className="right section">
                <div className="footer-title">
                  <h3>{this.state.lang.mainPage.footer.columns[2].title}</h3>
                </div>
                <div className="footer-links footer-links-right">
                  <a href="/">{this.state.lang.mainPage.footer.columns[2].links[0]}</a>
                </div>
                <div className="footer-links footer-links-right" style={{ marginTop: '0.1em' }}>
                  <p>info@keypro360.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="payment">
          <img src={paypal} alt="Keyboard Brazil Key Pro Stand 360 Keyboardist Music Rock" />
          <img src={mastercard} alt="Keyboard Brazil Key Pro Stand 360 Keyboardist Music Rock" />
          <img src={visa} alt="Keyboard Brazil Key Pro Stand 360 Keyboardist Music Rock" />
          <img src={americanexpress} alt="Keyboard Brazil Key Pro Stand 360 Keyboardist Music Rock" />
          <img src={elo} alt="Keyboard Brazil Key Pro Stand 360 Keyboardist Music Rock" />
        </div>

        <div className="copyright">
          Copyright &copy; 2020 Key Pro. All rights reserved. Website designed and developed by{' '}
          <a href="https://github.com/robertfedus" target="_blank" rel="noopener noreferrer">
            Robert Feduș
          </a>{' '}
          @{' '}
          <a href="/" target="_blank" rel="noopener noreferrer">
            Amber Tech
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
