import React, { Component } from 'react';

const List = props => (
  <li>
    <a href={props.link}>{props.page}</a>
  </li>
);

const links = [
  { name: 'Novidades', destination: `http://${window.location.host}#News` },
  { name: 'Sobre', destination: `http://${window.location.host}#About` },
  // { name: 'Artists', destination: 'Artists' },
  { name: 'Produtos', destination: `http://${window.location.host}/products` },
  { name: 'Contato', destination: `http://${window.location.host}#Contact` }
];

class Links extends Component {
  render() {
    return [
      <li>
        <a href="http://${window.location.host}#News">Novidades</a>
      </li>,
      <li>
        <a href="http://${window.location.host}#About">Novidades</a>
      </li>,
      <li>
        <a href="http://${window.location.host}/products">Novidades</a>
      </li>,
      <li>
        <a href="http://${window.location.host}#Contact">Novidades</a>
      </li>
    ];
  }
}

export default Links;
