import React, { Component } from 'react';
import './AdminPage.css';
import axios from 'axios';

class AdminPage extends Component {
  constructor(props) {
    super();

    this.state = {
      password: ''
    };
  }

  componentDidMount() {
    if (localStorage.getItem('jwt')) window.location.href = '/slider';
  }

  login = e => {
    e.preventDefault();

    const reqBody = { ...this.state };

    if (this.state.username === '' || this.state.password === '')
      return this.setState({ errorMessage: 'Incorrect password.' });
    else this.setState({ errorMessage: '' });

    const data = {
      password: this.state.password
    };

    axios
      .post('https://keypro-back.herokuapp.com/api/v1/auth/login', data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        window.location.href = '/slider';
        // document.cookie = `jwt=${response.data.data.token}`;
        localStorage.setItem('jwt', response.data.token);
        // window.location.reload();
      })
      .catch(e => {
        this.setState({ errorMessage: 'Incorrect password.' });
      });
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    let errorMessage = this.state.errorMessage;

    const loginForm = (
      <form className="login-form" onSubmit={this.login}>
        <input type="password" placeholder="Enter password" name="password" onChange={this.changeHandler} />
        <input type="submit" value="Submit" />

        <p style={{ marginTop: '2em' }}>{errorMessage}</p>
      </form>
    );

    return (
      <div className="AdminPage">
        <div>{loginForm}</div>
      </div>
    );
  }
}

export default AdminPage;
