import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Socials.css';

const Socials = props => {
  return (
    <div className="socials-wrapper">
      <div className="socials">
        <a
          href="https://www.facebook.com/keyprostand360"
          className="facebook-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faFacebookF} size="3x" className="icon" />
        </a>

        <a
          href="https://www.instagram.com/keyprostand360/"
          className="facebook-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faInstagram} size="3x" className="icon" />
        </a>

        <a
          href="https://www.youtube.com/channel/UCM940IX_8eE48GQ2F926WtA"
          className="facebook-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={faYoutube} size="3x" className="icon" />
        </a>
      </div>
    </div>
  );
};

export default Socials;
