import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';

const Arrow = props => {
  return (
    <FontAwesomeIcon
      icon={props.direction === 'right' ? faArrowRight : faArrowLeft}
      className={props.className}
      onClick={props.onClick}
    />
  );
};

export default Arrow;
