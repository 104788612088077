import React from 'react';
import './ProductsPage.css';

import Navbar from './../../containers/Navbar/Navbar';
import ModalImage from 'react-modal-image';

// import Carousel from './../Carousel/Carousel';

import Footer from './../../containers/Footer/Footer';

import pic1 from './../../assets/products_page/product1/1.jpg';
import pic2 from './../../assets/products_page/product1/2.jpg';
import pic3 from './../../assets/products_page/product1/3.jpg';
import pic4 from './../../assets/products_page/product1/4.jpg';

import pic5 from './../../assets/products_page/product2/1.jpg';
import pic6 from './../../assets/products_page/product2/2.jpg';
import pic7 from './../../assets/products_page/product2/3.jpg';
import pic8 from './../../assets/products_page/product2/4.jpg';

import pic9 from './../../assets/products_page/product3/1.jpg';
import pic10 from './../../assets/products_page/product3/2.jpg';
import pic11 from './../../assets/products_page/product3/3.jpg';
import pic12 from './../../assets/products_page/product3/4.jpg';

import pic13 from './../../assets/products_page/product4/1.jpg';
import pic14 from './../../assets/products_page/product4/2.jpg';
import pic15 from './../../assets/products_page/product4/3.jpg';

import pic17 from './../../assets/products_page/product5/1.jpg';
import pic18 from './../../assets/products_page/product5/2.jpg';
import pic19 from './../../assets/products_page/product5/3.jpg';
import pic20 from './../../assets/products_page/product5/4.jpg';

import pic21 from './../../assets/products_page/product6/1.jpg';
import pic22 from './../../assets/products_page/product6/2.jpg';
import pic23 from './../../assets/products_page/product7/1.jpg';
import pic24 from './../../assets/products_page/product7/2.jpg';
import pic25 from './../../assets/products_page/product7/3.jpg';

import pic26 from './../../assets/products_page/product8/1.jpg';
import pic27 from './../../assets/products_page/product8/2.jpg';
import pic28 from './../../assets/products_page/product8/3.jpg';
import pic29 from './../../assets/products_page/product8/4.jpg';

import pic30 from './../../assets/products_page/product9/1.jpeg';
import pic31 from './../../assets/products_page/product9/2.jpeg';
import pic37 from './../../assets/products_page/product9/3.jpeg';
import pic38 from './../../assets/products_page/product9/4.jpeg';

import pic32 from './../../assets/products_page/product10/1.jpeg';
import pic36 from './../../assets/products_page/product10/2.jpeg';
import pic33 from './../../assets/products_page/product11/1.jpeg';
import pic35 from './../../assets/products_page/product11/2.jpeg';
import pic34 from './../../assets/products_page/product12/1.jpeg';

import portuguese from './../../portuguese.json';
import english from './../../english.json';

import ReactImageZoom from 'react-image-zoom';
import Gallery from 'react-grid-gallery';
class ProductsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  componentDidMount() {
    document.body.id = 'products-body';
  }
  render() {
    return (
      <div className="products-page" style={{ height: '100%' }}>
        <Navbar />
        <main>
          <div className="title">
            <h1 className="main-title">{this.state.lang.productsPage.title}</h1>
          </div>
          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[0].title}</h1>
            <div className="images-container">
              <div className="images">
                {/* <Image src={pic1} /> */}
                <Gallery
                  images={[
                    {
                      src: pic1,
                      thumbnail: pic1,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic2,
                      thumbnail: pic2,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic3,
                      thumbnail: pic3,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic4,
                      thumbnail: pic4,
                      thumbnailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
                {/* <ModalImage small={pic1} large={pic1} hideZoom="true" />
              <ModalImage small={pic2} large={pic2} hideZoom="true" />
              <ModalImage small={pic3} large={pic3} hideZoom="true" />
              <ModalImage small={pic4} large={pic4} hideZoom="true" /> * */}
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              <span className="bolded">{this.state.lang.productsPage.products[0].performance.title}</span>{' '}
              <br />
              {this.state.lang.productsPage.products[0].performance.text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[0].specifications.title}</span>
              <br />
              {this.state.lang.productsPage.products[0].specifications.text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[0].composition.title}</span>
              <br />
              {this.state.lang.productsPage.products[0].composition.text[0]}
              <br />
              <br />
              {this.state.lang.productsPage.products[0].composition.text[1]}
              <br />
              <br />
              {this.state.lang.productsPage.products[0].composition.text[2]}
              <br />
              <br />
              {this.state.lang.productsPage.products[0].composition.text[3]}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[1].title}</h1>
            <div className="images-container">
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic5,
                      thumbnail: pic5,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic6,
                      thumbnail: pic6,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic7,
                      thumbnail: pic7,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic8,
                      thumbnail: pic8,
                      thumbnailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              <span className="bolded">{this.state.lang.productsPage.products[1].performance.title}</span>
              <br />
              {this.state.lang.productsPage.products[1].performance.text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[1].specifications.title}</span>
              <br />
              {this.state.lang.productsPage.products[1].specifications.text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[1].composition.title}</span>
              <br />
              {this.state.lang.productsPage.products[1].composition.text[0]}
              <br />
              <br />
              {this.state.lang.productsPage.products[1].composition.text[1]}
              <br />
              <br />
              {this.state.lang.productsPage.products[1].composition.text[2]}
              <br />
              <br />
              <br />
              {this.state.lang.productsPage.products[1].composition.text[3]}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[2].title}</h1>
            <div className="images-container">
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic9,
                      thumbnail: pic9,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic10,
                      thumbnail: pic10,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic11,
                      thumbnail: pic11,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic12,
                      thumbnail: pic12,
                      thumbnailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              <span className="bolded">{this.state.lang.productsPage.products[2].performance.title}</span>
              <br />
              {this.state.lang.productsPage.products[2].performance.text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[2].specifications.title}</span>
              <br />
              {this.state.lang.productsPage.products[2].specifications.text}
              <br />
              {this.state.lang.productsPage.products[2].specifications.text2}
              <br />
              <br />
              {this.state.lang.productsPage.products[2].specifications.column1.title}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column1.list[0]}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column1.list[1]}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column1.list[2]}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column1.list[3]}
              <br />
              <br />
              {this.state.lang.productsPage.products[2].specifications.column2.title}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column2.list[0]}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column2.list[1]}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column2.list[2]}
              <br />
              {this.state.lang.productsPage.products[2].specifications.column2.list[3]}
              <br />
              <br />
              {this.state.lang.productsPage.products[2].specifications.text3}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[2].composition.title}</span>
              <br />
              {this.state.lang.productsPage.products[2].composition.text[0]}
              <br />
              <br />
              {this.state.lang.productsPage.products[2].composition.text[1]}
              <br />
              <br />
              {this.state.lang.productsPage.products[2].composition.text[2]}
              <br />
              <br />
              <br />
              {this.state.lang.productsPage.products[2].composition.text[3]}
              <br />
              <br />
              {this.state.lang.productsPage.products[2].composition.text[4]}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[3].title}</h1>
            <div className="images-container">
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic13,
                      thumbnail: pic13,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic14,
                      thumbnail: pic14,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic15,
                      thumbnail: pic15,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>
            <p className="paragraph">
              {this.state.lang.productsPage.products[3].text}
              <br />
              <br />{' '}
              <span className="bolded">{this.state.lang.productsPage.products[3].performance.title}</span>
              <br />
              {this.state.lang.productsPage.products[3].performance.text1}
              <br />
              <br />
              <br />
              {this.state.lang.productsPage.products[3].performance.text2}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[3].composition.title}</span>
              <br />
              {this.state.lang.productsPage.products[3].composition.text[0]}
              <br />
              <br />
              {this.state.lang.productsPage.products[3].composition.text[1]}
              <br />
              <br />
              {this.state.lang.productsPage.products[3].composition.text[2]}
              <br />
              <br />
              {this.state.lang.productsPage.products[3].composition.text[3]}
              <br />
              <br />
              <br />
              {this.state.lang.productsPage.products[3].composition.text[4]}
              <br />
              <br />
              {this.state.lang.productsPage.products[3].composition.text[5]}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[4].title}</h1>
            <div className="images-container">
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic17,
                      thumbnail: pic17,
                      thumbnailWidth: 350,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic18,
                      thumbnail: pic18,
                      thumbnailWidth: 350,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic19,
                      thumbnail: pic19,
                      thumbnailWidth: 350,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic20,
                      thumbnail: pic20,
                      thumbnailWidth: 350,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              <span className="bolded">{this.state.lang.productsPage.products[4].performance.title}</span>
              <br />
              {this.state.lang.productsPage.products[4].performance.text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[4].specifications.title}</span>
              <br />
              {this.state.lang.productsPage.products[4].specifications.text1}
              <br />
              {this.state.lang.productsPage.products[4].specifications.text2}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[5].title}</h1>
            <div className="images-container" style={{ width: '50%' }}>
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic21,
                      thumbnail: pic21,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic22,
                      thumbnail: pic22,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <br />
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              {this.state.lang.productsPage.products[5].text}
              <br />
              <br />
              <span className="bolded">{this.state.lang.productsPage.products[5].description.title}</span>
              <br />
              <br />
              {this.state.lang.productsPage.products[5].description.list[0]}
              <br />
              {this.state.lang.productsPage.products[5].description.list[1]}
              <br />
              {this.state.lang.productsPage.products[5].description.list[2]}
              <br />
              {this.state.lang.productsPage.products[5].description.list[3]}
              <br />
              {this.state.lang.productsPage.products[5].description.list[4]}
              <br />
              {this.state.lang.productsPage.products[5].description.list[5]}
              <br />
              {this.state.lang.productsPage.products[5].description.list[6]}
            </p>
          </div>
          <br />

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[6].title}</h1>
            <div className="images-container">
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic23,
                      thumbnail: pic23,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic24,
                      thumbnail: pic24,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic25,
                      thumbnail: pic25,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              {this.state.lang.productsPage.products[6].text1}
              <br />
              {this.state.lang.productsPage.products[6].text2}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[7].title}</h1>
            <div className="images-container">
              <div className="images">
                <Gallery
                  images={[
                    {
                      src: pic26,
                      thumbnail: pic26,
                      thumbailWidth: 300,
                      thumbnailHeight: 174
                    },
                    {
                      src: pic27,
                      thumbnail: pic27,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },

                    {
                      src: pic28,
                      thumbnail: pic28,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic29,
                      thumbnail: pic29,
                      thumbnailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <div style={{ marginTop: '15em' }}></div>

            <p className="paragraph">
              {this.state.lang.productsPage.products[7].text1}
              <br />
              <br />
              {this.state.lang.productsPage.products[7].columns[0].list[0]}
              <br />
              {this.state.lang.productsPage.products[7].columns[0].list[1]}
              <br />
              {this.state.lang.productsPage.products[7].columns[0].list[2]}
              <br />
              {this.state.lang.productsPage.products[7].columns[0].list[3]}
              <br />
              {this.state.lang.productsPage.products[7].columns[0].list[4]}
              <br />
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[0]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[1]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[2]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[3]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[4]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[5]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[6]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[7]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[8]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[9]}
              <br />
              {this.state.lang.productsPage.products[7].columns[1].list[10]}
              <br />
              <br />
              {this.state.lang.productsPage.products[7].columns[2].list[0]}
              <br />
              {this.state.lang.productsPage.products[7].columns[2].list[1]}
              <br />
              <br />
              {this.state.lang.productsPage.products[7].text2}
            </p>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[8].title}</h1>
            <div className="images-container">
              <div className="images last">
                <Gallery
                  images={[
                    {
                      src: pic30,
                      thumbnail: pic30,
                      thumbailWidth: 250,
                      thumbnailHeight: 500
                    },
                    {
                      src: pic31,
                      thumbnail: pic31,
                      thumbailWidth: 250,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic37,
                      thumbnail: pic37,
                      thumbailWidth: 250,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic38,
                      thumbnail: pic38,
                      thumbailWidth: 200,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>
            <br />
            <div style={{ marginTop: '24em' }}>
              <p className="paragraph">
                {this.state.lang.productsPage.products[8].text1}
                <br />
                {this.state.lang.productsPage.products[8].text2}
                <br />
                {this.state.lang.productsPage.products[8].text3}
                <br />
                {this.state.lang.productsPage.products[8].text4}
                <br />
                {this.state.lang.productsPage.products[8].text5}
                <br />
                <br />
                <span className="bolded">
                  {this.state.lang.productsPage.products[4].specifications.title}
                </span>
                <br />
                {this.state.lang.productsPage.products[8].specifications[0]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[1]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[2]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[3]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[4]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[5]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[6]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[7]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[8]}
                <br />
                {this.state.lang.productsPage.products[8].specifications[9]}
              </p>
            </div>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[9].title}</h1>
            <div className="images-container">
              <div className="images last">
                <Gallery
                  images={[
                    {
                      src: pic32,
                      thumbnail: pic32,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic36,
                      thumbnail: pic36,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>

            <div style={{ marginTop: '15em' }}>
              <p className="paragraph">
                <span className="bolded">
                  {this.state.lang.productsPage.products[9].specifications.title}
                </span>

                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[0]}
                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[1]}
                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[2]}
                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[3]}
                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[4]}
                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[5]}
                <br />
                {this.state.lang.productsPage.products[9].specifications.specs[6]}
              </p>
            </div>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[10].title}</h1>
            <div className="images-container">
              <div className="images last">
                <Gallery
                  images={[
                    {
                      src: pic33,
                      thumbnail: pic33,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    },
                    {
                      src: pic35,
                      thumbnail: pic35,
                      thumbailWidth: 300,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>

            <div style={{ marginTop: '15em' }}>
              <p className="paragraph">
                <span className="bolded">
                  {this.state.lang.productsPage.products[10].specifications.title}
                </span>

                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[0]}
                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[1]}
                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[2]}
                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[3]}
                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[4]}
                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[5]}
                <br />
                {this.state.lang.productsPage.products[10].specifications.specs[6]}
              </p>
            </div>
          </div>

          <div className="product">
            <h1 className="section-title">{this.state.lang.productsPage.products[11].title}</h1>
            <div className="images-container">
              <div className="images last modified">
                <Gallery
                  images={[
                    {
                      src: pic34,
                      thumbnail: pic34,
                      thumbailWidth: 195,
                      thumbnailHeight: 212
                    }
                  ]}
                />
              </div>
            </div>

            <div style={{ marginTop: '15em' }}>
              <p className="paragraph">
                <span className="bolded">
                  {this.state.lang.productsPage.products[11].specifications.title}
                </span>

                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[0]}
                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[1]}
                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[2]}
                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[3]}
                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[4]}
                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[5]}
                <br />
                {this.state.lang.productsPage.products[11].specifications.specs[6]}
              </p>
            </div>
          </div>

          <p style={{ marginTop: '1.5em', fontSize: '30px' }}>{this.state.lang.productsPage.mention}</p>
        </main>
        <Footer />
      </div>
    );
  }
}

export default ProductsPage;
