import React from 'react';
import logo from './../../assets/logo.png';

const Image = props => {
  return (
    <div className={`header__logo-container ${props.classes}`}>
      <img src={logo} alt="Key Pro" className="header__logo" />
    </div>
  );
};

export default Image;
