import React, { Component } from 'react';

import Navbar from './../../containers/Navbar/Navbar';
import Header from './../../components/Header/Header';
import News from './../../containers/News/News';
import About from './../../containers/About/About';
// import Carousel from './../Carousel/Carousel';
import Contact from './../../components/Contact/Contact';
import Footer from './../../containers/Footer/Footer';
import Products from './../../containers/Products/Products';
import ImageCarousel from './../../components/Carousel/Carousel';
import Socials from './../../containers/Socials/Socials';

class MainPage extends Component {
  componentDidMount() {
    document.body.style.backgroundColor = 'white';
  }

  render() {
    return (
      <div style={{ height: '100%', textAlign: 'center' }}>
        <ImageCarousel />
        <Navbar />

        <News />
        <Socials />
        <Products />
        <main>
          <About />
        </main>
        <Header />
        <Contact />

        <Footer />
        {/* <Carousel /> */}
      </div>
    );
  }
}

export default MainPage;
