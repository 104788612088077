import React, { Component } from 'react';
import logo from '../../assets/logo.png';
import './Nav.css';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';
import brazilFlag from './../../assets/brazil.png';
import ukFlag from './../../assets/uk.png';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

// import Links from './List';

class Nav extends Component {
  constructor() {
    super();

    this.state = {
      scrolled: false,
      flag: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');
    if (currentLang === 'en') this.setState({ flag: false });
    else if (currentLang === 'por') this.setState({ flag: true });
    else if (!currentLang) this.setState({ flag: true });
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {
      const isTop = window.scrollY < 30;
      if (!isTop) {
        this.setState({ scrolled: true });
      } else {
        this.setState({ scrolled: false });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll');
  }

  changeLanguage = lang => {
    // const currentLang = localStorage.getItem('lang');
    if (lang === 'eng') {
      localStorage.setItem('lang', 'eng');
      this.setState({ flag: true });
    } else if (lang === 'por') {
      localStorage.setItem('lang', 'por');
      this.setState({ flag: false });
    } else {
      localStorage.setItem('lang', 'eng');
      this.setState({ flag: false });
    }

    window.location.reload();
  };

  scrollTo = destination => {
    // const elmnt = document.getElementById(destination);
    // elmnt.scrollIntoView();
    // console.log(elmnt);
    window.location.href = '#';
    window.location.href = '#' + destination;
  };

  render() {
    let flagImage, links;
    if (this.state.flag) flagImage = ukFlag;
    else flagImage = brazilFlag;

    if (this.state.flag) {
      links = [
        { name: 'Novidades', destination: `http://${window.location.host}#News` },
        { name: 'Sobre', destination: `http://${window.location.host}#About` },
        // { name: 'Artists', destination: 'Artists' },
        { name: 'Produtos', destination: `http://${window.location.host}/products` },
        { name: 'Loja', destination: `http://keypro.mercadoshops.com.br/` },
        { name: 'Contato', destination: `http://${window.location.host}#Contact` }
      ];
    } else {
      links = [
        { name: 'Novidades', destination: `http://${window.location.host}#News` },
        { name: 'Sobre', destination: `http://${window.location.host}#About` },
        // { name: 'Artists', destination: 'Artists' },
        { name: 'Produtos', destination: `http://${window.location.host}/products` },
        { name: 'Contato', destination: `http://${window.location.host}#Contact` }
      ];
    }

    let linkNames;
    // Daca e true atunci suntem in portugheza
    if (this.state.flag) linkNames = portuguese.mainPage.navbar;
    if (!this.state.flag) linkNames = english.mainPage.navbar;

    const Links = links.map((link, i) => (
      <li>
        <a style={{ cursor: 'pointer' }} href={link.destination}>
          {linkNames[i]}
        </a>
      </li>
    ));
    const { scrolled } = this.state;

    // prettier-ignore
    let navClasses = 'toolbar__navigation' + (scrolled ? ' navigation-scrolled' : '');
    let iconClass = 'icon' + (scrolled ? ' icon-scrolled' : '');
    let ulClass = scrolled ? 'ul-scrolled' : 'ul-top';
    // prettier-ignore
    let toggleButtonClass = scrolled ? 'toggle-button__line-scrolled' : undefined;
    let logoClass = scrolled ? 'logo__scrolled' : undefined;

    return (
      <header className="toolbar">
        <nav className={navClasses}>
          <div className="toolbar__toggle-button">
            <DrawerToggleButton click={this.props.drawerClickHandler} classes={toggleButtonClass} />
          </div>
          <div style={{ textAlign: 'center' }}>
            <div className={`toolbar__logo ${logoClass}`}>
              <a href="/">
                <img src={logo} alt="KeyPro" />
              </a>
            </div>
            <div className={`toolbar__logo__mobile`}>
              <a href="/">
                <img src={logo} alt="KeyPro" />
              </a>
            </div>
          </div>
          <div className="spacer" />
          <div className="toolbar__navigation-items">
            <ul className={ulClass}>
              {Links}
              <a
                href="https://www.facebook.com/keyprostand360"
                className="facebook-icon"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '1em' }}
              >
                <FontAwesomeIcon
                  icon={faFacebookF}
                  size="2x"
                  style={{ color: '#3b5998' }}
                  className={iconClass}
                />
              </a>

              <a
                href="https://www.instagram.com/keyprostand360/"
                className="facebook-icon"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '1em' }}
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="2x"
                  style={{ color: '#C13584' }}
                  className={iconClass}
                />
              </a>

              <a
                href="https://www.youtube.com/channel/UCM940IX_8eE48GQ2F926WtA"
                className="facebook-icon"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '1em' }}
              >
                <FontAwesomeIcon
                  icon={faYoutube}
                  size="2x"
                  style={{ color: '#FF0000' }}
                  className={iconClass}
                />
              </a>

              <a
                href="https://twitter.com/KEYPRO18"
                className="facebook-icon"
                target="_blank"
                rel="noopener noreferrer"
                style={{ marginLeft: '1em' }}
              >
                <FontAwesomeIcon
                  icon={faTwitter}
                  size="2x"
                  style={{ color: '#1DA1F2' }}
                  className={iconClass}
                />
              </a>

              <img
                src={brazilFlag}
                alt="key pro stand 360"
                className="language-flag"
                onClick={() => this.changeLanguage('por')}
              />
              <img
                src={ukFlag}
                alt="key pro stand 360"
                className="language-flag"
                onClick={() => this.changeLanguage('eng')}
              />
            </ul>
          </div>
        </nav>
      </header>
    );
  }
}

// const List = props => (
//   <li onClick={() => Nav.scrollTo(props.link)}>
//     <a href="#">{props.page}</a>
//   </li>
// );

export default Nav;
