import React, { Component } from 'react';
import './SideDrawer.css';
import Links from './../Nav/List';
import logo from '../../assets/logo.png';
import brazilFlag from './../../assets/brazil.png';
import ukFlag from './../../assets/uk.png';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class SideDrawer extends Component {
  constructor() {
    super();

    this.state = {
      flag: undefined,
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');
    if (currentLang === 'en') this.setState({ flag: false });
    else if (currentLang === 'por') this.setState({ flag: true });
    else if (!currentLang) this.setState({ flag: true });

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  changeLanguage = () => {
    const currentLang = localStorage.getItem('lang');
    if (currentLang === 'por') {
      localStorage.setItem('lang', 'eng');
      this.setState({ flag: true });
    } else if (currentLang === 'eng') {
      localStorage.setItem('lang', 'por');
      this.setState({ flag: false });
    } else {
      localStorage.setItem('lang', 'eng');
      this.setState({ flag: false });
    }

    window.location.reload();
  };

  render() {
    let flagImage;
    if (this.state.flag) flagImage = ukFlag;
    else flagImage = brazilFlag;
    let drawerClasses = 'side-drawer';

    if (this.props.show) {
      drawerClasses = 'side-drawer open';
    }
    return (
      <nav className={drawerClasses}>
        <img src={logo} className="side-drawer-logo" alt="Key Pro" />
        <ul>
          <li>
            <a href={`http://${window.location.host}#News`}>{this.state.lang.mainPage.navbar[0]}</a>
          </li>
          <li>
            <a href={`http://${window.location.host}#About`}>{this.state.lang.mainPage.navbar[1]}</a>
          </li>
          <li>
            <a href={`http://${window.location.host}/products`}>{this.state.lang.mainPage.navbar[2]}</a>
          </li>
          <li>
            <a href={`http://${window.location.host}#Contact`}>{this.state.lang.mainPage.navbar[3]}</a>
          </li>
        </ul>

        <img
          src={brazilFlag}
          alt="key pro stand 360"
          className="language-flag"
          onClick={() => this.changeLanguage('por')}
        />
        <img
          src={ukFlag}
          alt="key pro stand 360"
          className="language-flag"
          onClick={() => this.changeLanguage('eng')}
        />
      </nav>
    );
  }
}
export default SideDrawer;
