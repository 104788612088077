import React from 'react';
import './New.css';

const New = props => {
  const imageStyle = {
    backgroundImage: `url(${props.image})`
  };

  return (
    <div className={`new-wrapper ${props.classes}`}>
      <div className="new-image" style={imageStyle}></div>
      <div className="new-content">
        <h4>{props.title}</h4>

        <div className="text-wrapper">
          <p>{props.text}</p>
        </div>

        {/* <div className="date">
          <span>{props.date}</span>
        </div> */}
      </div>
    </div>
  );
};

export default New;
