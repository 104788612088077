import React, { Component } from 'react';
import axios from 'axios';

import './News.css';
import NewList from './List';
import New from './../../components/New/New';
import Arrow from './Arrow';

import pic1 from './../../assets/news/pic1.jpeg';
import pic2 from './../../assets/news/pic2.jpeg';
import pic3 from './../../assets/news/pic3.jpeg';
// import portuguese from './../../portuguese.json';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

const images = [pic1, pic2, pic3];

class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newsPosition: 0, // 0 last, 2 first
      windowWidth: undefined,
      news: [{}, {}, {}],
      lang: undefined
    };

    window.addEventListener('resize', this.update);
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  componentDidMount() {
    this.update();
    // this.getNews();
  }

  // getNews() {
  //   axios
  //     .get('https://keypro-back.herokuapp.com/api/v1/admin/news')
  //     .then(response => {
  //       this.response = { ...response };

  //       if (this.response.status === 200) {
  //         const dateOptions = {
  //           weekday: 'short',
  //           year: 'numeric',
  //           month: 'short',
  //           day: 'numeric'
  //         };

  //         this.setState({ isLoading: false });

  //         this.response.data.data.forEach(date => {
  //           const modifiedDate = new Date(date.date);
  //           date.date = modifiedDate.toLocaleDateString('en-US', dateOptions);
  //         });
  //         this.setState({ news: this.response.data.data });
  //       }
  //     })
  //     .catch(e => {
  //       console.log('error:\n' + e);
  //     });
  // }

  update = () => {
    this.setState({
      windowWidth: window.innerWidth
    });
  };

  switchNewsController(arrow) {
    if (arrow === 'right' && this.state.newsPosition <= 1) {
      this.setState({
        newsPosition: this.state.newsPosition + 1
      });
    } else if (arrow === 'left' && this.state.newsPosition >= 1) {
      this.setState({
        newsPosition: this.state.newsPosition - 1
      });
    }
  }

  render() {
    // const portugueseNews = portuguese.main_page.news;
    if (this.state.windowWidth <= 1010) {
      const newIndex = this.state.newsPosition;
      this.news = (
        <New
          image={images[newIndex]}
          title={this.state.lang.mainPage.newsTitles[newIndex]}
          text={this.state.lang.mainPage.newsTexts[newIndex]}
        />
      );
    } else this.news = <NewList />;

    return (
      <div className="news" id="News">
        <h1 className="section-title">{this.state.lang.mainPage.news}</h1>
        <div className="list">
          <Arrow
            direction="left"
            className="arrow arrow-left"
            onClick={() => this.switchNewsController('left')}
          />
          {this.news}
          <Arrow
            direction="right"
            className="arrow arrow-right"
            onClick={() => this.switchNewsController('right')}
          />
        </div>
      </div>
    );
  }
}

export default News;
