import React, { Component } from 'react';
import axios from 'axios';
import './Contact.css';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      country: '',
      state: '',
      city: '',
      postalCode: '',
      message: '',
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  sendState = () => {
    let param = false;

    if (this.state.loggedIn) {
      param = true;
    }
    return this.props.parentCallback(param);
  };

  changeHandler = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  submitHandler = event => {
    event.preventDefault();
    const reqBody = { ...this.state };
    axios
      .post('https://keypro-back.herokuapp.com/api/v1/public/contact', reqBody)
      .then(response => {
        this.response = { ...response };
        alert(this.response.data.message);
        window.location.reload();
      })
      .catch(e => {
        alert('Error sending the message');
      });
  };

  render() {
    const { name, email, country, state, city, postalCode, message } = this.state;

    return (
      <div className="contact-section" id="Contact">
        <h1 className="section-title">{this.state.lang.mainPage.contactTitle}</h1>
        <br />
        <div className="p-wrapper">
          <p className="header__p">{this.state.lang.mainPage.contact.description}</p>
        </div>
        <form
          // action="127.0.0.1:5000/api/v1/admin/login"
          // method="post"
          className="contact-form"
          onSubmit={this.submitHandler}
        >
          <label>
            {this.state.lang.mainPage.contact.fields[0]}
            <span>*</span>
          </label>
          <input
            type="text"
            // placeholder="Full name"
            name="name"
            value={name}
            onChange={this.changeHandler}
            required
          />
          <br />
          <label>
            {this.state.lang.mainPage.contact.fields[1]} <span>*</span>
          </label>
          <input
            type="text"
            // placeholder="Email"
            name="email"
            value={email}
            onChange={this.changeHandler}
            required
          />
          <br />
          <label>{this.state.lang.mainPage.contact.fields[2]}</label>
          <input
            type="text"
            // placeholder="Country"
            name="country"
            value={country}
            onChange={this.changeHandler}
          />
          <br />
          <label>{this.state.lang.mainPage.contact.fields[3]}</label>
          <input
            type="text"
            // placeholder="Country"
            name="state"
            value={state}
            onChange={this.changeHandler}
          />
          <br />
          <label>{this.state.lang.mainPage.contact.fields[4]}</label>
          <input
            type="text"
            // placeholder="Country"
            name="city"
            value={city}
            onChange={this.changeHandler}
          />
          <br />
          <label>{this.state.lang.mainPage.contact.fields[5]}</label>
          <input
            type="text"
            // placeholder="Country"
            name="postalCode"
            value={postalCode}
            onChange={this.changeHandler}
          />
          <br />
          <label>
            {this.state.lang.mainPage.contact.fields[6]} <span>*</span>
          </label>
          <textarea
            type="text"
            // placeholder="Message"
            name="message"
            value={message}
            onChange={this.changeHandler}
            className="message"
            required
          />
          <br />
          <button type="submit" className="submit-button">
            {this.state.lang.mainPage.contact.button}
          </button>
        </form>
        <p>{this.state.lang.mainPage.contact.mention}</p>
      </div>
    );
  }
}

export default Contact;
