import React, { Component } from 'react';
import './Product.css';

class Product extends Component {
  render() {
    const imageStyle = {
      backgroundImage: `url(${this.props.image})`
    };

    return (
      <div className="product-wrapper">
        <div className="product-image" style={imageStyle}></div>
        <div className="product-content">
          <h4>{this.props.title}</h4>

          <div className="text-wrapper">
            <p>{this.props.text}</p>
          </div>

          <div className="details">
            <span>
              <a href={this.props.link}>{this.props.linkText}</a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Product;
