import React, { Component } from 'react';
import Image from './Image';
import './Header.css';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  render() {
    return (
      <div className="header">
        {/* {props.children} */}
        <div className="header__content">
          <div className="left">
            {/* <Image classes="header__image-mobile" /> */}
            <h1 className="main-title">{this.state.lang.mainPage.banner.title}</h1>

            <p className="header__p">{this.state.lang.mainPage.banner.description}</p>
            {/* <button className=" button-header full__button-header">
            Adquira a sua
          </button> */}
            <a href="/products">
              <button className=" button-header empty__button-header">
                {this.state.lang.mainPage.banner.button}
              </button>
            </a>
          </div>

          <Image classes="header__image-desktop" />
        </div>
      </div>
    );
  }
}

export default Header;
