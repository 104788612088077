import React, { Component } from 'react';
import './Products.css';
import Product from './../../components/Product/Product';
import stand from './../../assets/products/main_page_display/stand.jpeg';
import bag from './../../assets/products/main_page_display/bag.jpeg';
import product2 from './../../assets/products/main_page_display/product2.jpeg';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  render() {
    return (
      <div className="products" id="Products">
        <h1 className="section-title">{this.state.lang.mainPage.productsTitle}</h1>

        <div className="list">
          <Product
            image={stand}
            title={this.state.lang.mainPage.products[0].title}
            text={this.state.lang.mainPage.products[0].description}
            link="/products"
            linkText={this.state.lang.mainPage.products[0].button}
          />
          <Product
            image={product2}
            title={this.state.lang.mainPage.products[1].title}
            text={this.state.lang.mainPage.products[1].description}
            link="/products"
            linkText={this.state.lang.mainPage.products[1].button}
          />
          <Product
            image={bag}
            title={this.state.lang.mainPage.products[2].title}
            text={this.state.lang.mainPage.products[2].description}
            link="/products"
            linkText={this.state.lang.mainPage.products[2].button}
          />
        </div>
      </div>
    );
  }
}

export default Products;
