import React, { Component } from 'react';
import './About.css';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: undefined
    };
  }

  componentWillMount() {
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  render() {
    return (
      <div className="about" id="About">
        <h1 className="section-title">{this.state.lang.mainPage.aboutTitle}</h1>
        <h2>{this.state.lang.mainPage.about.title}</h2>
        <p className="paragraph">
          {this.state.lang.mainPage.about.text}
          <br />
          <br />
          {this.state.lang.mainPage.about.quote}
        </p>
        <h4 style={{ textAlign: 'left' }}>
          Evaristo Fernandes CEO <span style={{ color: 'chocolate' }}>–</span> Key Pro
        </h4>
      </div>
    );
  }
}

export default About;
