import React, { Component } from 'react';
import './Slider.css';
import axios from 'axios';

class AdminSliderEnglish extends Component {
  state = {
    selectedFile: undefined,
    existingPictures: undefined
  };

  componentDidMount = () => {
    if (!localStorage.getItem('jwt')) window.location.href = '/';

    axios
      .get('https://keypro-back.herokuapp.com/api/v1/admin/slider/english', {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        this.setState({ existingPictures: response.data.data });
      })
      .catch(e => {
        console.log(e);
      });
  };

  fileChangedHandler = event => {
    this.setState({
      selectedFile: event.target.files[0]
    });
  };

  sendToDB = link => {
    const data = {
      link
    };
    axios
      .post('https://keypro-back.herokuapp.com/api/v1/admin/slider/english', data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          jwt: localStorage.getItem('jwt')
        }
      })
      .then(response => {
        // window.location.href = '/slider';
        // document.cookie = `jwt=${response.data.data.token}`;
        window.location.reload();
      })
      .catch(e => {
        console.log(e);
      });
  };

  submitHandler = event => {
    event.preventDefault();
    // const reqBody = { ...this.state };
    // console.log(reqBody);

    const data = new FormData();
    data.append('image', this.state.selectedFile);
    axios
      .post('https://api.imgur.com/3/image', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Client-ID aa4f70aa066eefe'
        }
        // Authorization: 'Bearer ' + varToken
      })
      .then(response => {
        this.response = { ...response };
        // window.location.href = '/projects';
        this.sendToDB(response.data.data.link);
        alert('Success!');
      })
      .catch(e => {
        alert('Error: Upload a picture first! / Max limit: 10MB');
        console.log(e.response);
        console.log('Error');
      });
  };

  deleteRequest = link => {
    const confirmation = window.confirm('Are you sure you want to delete this picture?');

    if (confirmation) {
      axios
        .delete('https://keypro-back.herokuapp.com/api/v1/admin/slider/english?link=' + link, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            jwt: localStorage.getItem('jwt')
          }
        })
        .then(response => {
          window.location.reload();
        })
        .catch(e => {
          window.location.reload();
          console.log(e);
        });
    }
  };

  logout = () => {
    localStorage.removeItem('jwt');
    window.location.href = '/';
  };

  render() {
    let existingPictures;
    if (this.state.existingPictures) {
      existingPictures = (
        <div>
          {this.state.existingPictures.map((picture, index) => {
            return (
              <div key={index}>
                <img src={picture.link} alt="KEY PRO STAND 360" className="existing-picture" />

                <h5
                  onClick={() => this.deleteRequest(picture.link)}
                  style={{ color: 'red', cursor: 'pointer' }}
                >
                  Delete image
                </h5>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="AdminSlider">
        <span
          style={{ marginBottom: '3em', display: 'block', cursor: 'pointer', color: 'red' }}
          onClick={this.logout}
        >
          Click here to log out
        </span>

        <h2>Upload image:</h2>
        <br />
        <br />
        <form onSubmit={this.submitHandler}>
          <input type="file" onChange={this.fileChangedHandler} />

          <input type="submit" value="Click here to upload" style={{ display: 'block', marginTop: '1em' }} />
        </form>
        <br />
        <br />
        <h2>Slider images:</h2>
        {existingPictures}
      </div>
    );
  }
}

export default AdminSliderEnglish;
