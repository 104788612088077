import React, { Component } from 'react';
import axios from 'axios';

import Aux from './../../hoc/Aux/Aux';
import New from './../../components/New/New';
import './News.css';
import pic1 from './../../assets/news/pic1.jpeg';
import pic2 from './../../assets/news/pic2.jpeg';
import pic3 from './../../assets/news/pic3.jpeg';
import portuguese from './../../portuguese.json';
import english from './../../english.json';

class NewsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      news: [{}, {}, {}],
      lang: undefined
    };
  }

  componentWillMount() {
    this.state.isLoading = false;
    const currentLang = localStorage.getItem('lang');

    if (currentLang === 'eng') this.setState({ lang: english });
    else if (currentLang === 'por' || !currentLang) this.setState({ lang: portuguese });
    else this.setState({ lang: portuguese });
  }

  // getNews() {
  //   axios
  //     .get('https://keypro-back.herokuapp.com/api/v1/admin/news')
  //     .then(response => {
  //       this.response = { ...response };

  //       if (this.response.status === 200) {
  //         const dateOptions = {
  //           weekday: 'short',
  //           year: 'numeric',
  //           month: 'short',
  //           day: 'numeric'
  //         };

  //         this.setState({ isLoading: false });

  //         this.response.data.data.forEach(date => {
  //           const modifiedDate = new Date(date.date);
  //           date.date = modifiedDate.toLocaleDateString('en-US', dateOptions);
  //         });
  //         this.setState({ news: this.response.data.data });
  //       }
  //     })
  //     .catch(e => {
  //       console.log('error:\n' + e);
  //     });
  // }

  // componentDidMount() {
  //   this.getNews();
  // }

  render() {
    let content = <div>Loading...</div>;
    if (!this.state.isLoading) {
      content = (
        <Aux>
          <New
            image={pic1}
            title={this.state.lang.mainPage.newsTitles[0]}
            text={this.state.lang.mainPage.newsTexts[0]}
            // date={this.state.news[0].date}
            classes="new-1"
          />
          <New
            image={pic2}
            title={this.state.lang.mainPage.newsTitles[1]}
            text={this.state.lang.mainPage.newsTexts[1]}
            // date={this.state.news[1].date}
            classes="new-2"
          />
          <New
            image={pic3}
            title={this.state.lang.mainPage.newsTitles[2]}
            text={this.state.lang.mainPage.newsTexts[2]}
            // date={this.state.news[2].date}
            classes="new-3"
          />
        </Aux>
      );
    }
    return content;
  }
}

export default NewsList;
